<div *ngIf="pageLoader" class="flexbox">
  <div>
    <div class="dbl-spinner"></div>
    <div class="dbl-spinner dbl-spinner--2"></div>
  </div>
</div>

<ng-container *ngIf="data">
  <div class="error-sec" *ngIf="!data.details">
    <img src="../../assets/images/error.png" alt="error" class="error-img" />
    <p class="error-txt">Invalid restaurant</p>
  </div>
  <div *ngIf="data.details" class="resturant-page">
    <div class="menu-top-sec">
      <div class="header-menu-img">
        <img alt="" class="header-img" src="{{ data.details.banner_image }}" />
        <div class="resturant-logo-sec">
          <img alt="" class="" src="{{ data.details.logo }}" />
          <!-- <img alt="" class="" src="{{data.details.logo}}"> -->
        </div>
      </div>
      <div class="menu-all-sec">
        <div class="resturant-name-sec">
          <div class="resturant-name">
            <h4 class="heading-24">{{ data.details.name }}</h4>
            <!-- <p class="resturant-location">Location of restaurant</p> -->
            <!-- <div class="total-rating-sec">
              <div class="rating-sec">
                <div class="star-ratings-sprite">
                  <span
                    [style.width.%]="(data.details.meta.rating * 100) / 5"
                    class="star-ratings-sprite-rating"
                  ></span>
                </div>
              </div>
              <p class="total-rating">{{ data.details.meta.rating }}</p>
            </div> -->
            <p class="restaurant-location">{{ data.details.meta.address }}</p>
            <!-- <div class="timeText"><span class="openText">Open now -</span> 9am - 2am (All days)</div> -->
          </div>
        </div>
        <div class="filter-sec">
          <div class="filtere-top-sec">
            <div class="search-sec">
              <input
                (keyup)="searchItem($event.target, 'text')"
                class="search-input"
                value="{{ searchText }}"
                placeholder="Search with in menu"
                type="text"
              />
              <img
                alt="search"
                class="search-img"
                src="../../assets/images/search.png"
              />
              <button
                *ngIf="searchText"
                (click)="clearFilter()"
                class="search-close"
              >
                <img
                  src="../../assets/images/close-icon.png"
                  alt="search"
                  class="close-search"
                />
              </button>
            </div>
          </div>
          <div class="filter-bottom-sec">
            <div
              *ngFor="let cat of data.categories"
              class="filter-btn-item"
              [ngClass]="{ active: activeCat == cat }"
            >
              <button
                (click)="searchItem(cat, 'cat')"
                class="filter-cat-btn normal-txt"
              >
                {{ cat }}
              </button>
              <button class="filter-close-btn" *ngIf="activeCat == cat">
                <img
                  src="../../assets/images/close-icon.png"
                  class="close-icon"
                />
                <img
                  src="../../assets/images/close-blue.png"
                  (click)="clearFilter()"
                  class="close-blue"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="menu-listing-sec">
          <ng-container *ngIf="!searchText">
            <div
              *ngFor="let cat of data.categories"
              class="item-main-container"
            >
              <h4 class="menu-top-heading">{{ cat }}</h4>
              <div class="menu-inner-list">
                <div
                  *ngFor="let item of data.items[cat]"
                  class="menu-list-item"
                >
                  <div
                    class="menu-item-contant {{
                      largeImage[item.id] ? 'menu-img-lg' : ''
                    }}"
                    [ngClass]="item.image ? 'menu-img-sm' : ''"
                  >
                    <div class="menu-contant-top">
                      <div class="menu-contant-left">
                        <div class="food_icons" *ngIf="item.food_types">
                          <ng-container
                            *ngFor="let food_type of shortFoodType(item.food_types)"
                          >
                          <ng-container *ngIf="food_type === 'non-veg' || food_type === '0'; else vegFoodType">
                            <img src="./../../assets/images/food-icons/non-veg.png" />
                          </ng-container>
                          <ng-template #vegFoodType>
                            <ng-container *ngIf="food_type === 'veg' || food_type === '1' || food_type === '2'; else otherFoodType">
                              <img src="./../../assets/images/food-icons/veg.png" />
                            </ng-container>
                            <ng-template #otherFoodType>
                              <img src="./../../assets/images/food-icons/{{ food_type }}.png" />
                            </ng-template>
                          </ng-template>
                          </ng-container>
                        </div>
                        <div
                          class="restaurantName {{
                            item.is_veg == 2 ? 'ml24' : ''
                          }}"
                        >
                          <div
                            class="menu-green-dot"
                            *ngIf="item.is_veg == 1"
                          ></div>
                          <div
                            class="menu-red-dot"
                            *ngIf="item.is_veg == 0"
                          ></div>
                          <h4 class="menu-item-heading">{{ item.name }}</h4>
                        </div>
                        <p
                          *ngIf="item.price"
                          class="menu-amount normal-txt ml24 mb5"
                        >
                          <span class="font400">{{ item.is_show_currency ? '₹' : 'Price ' }}</span>{{ item.price }}
                        </p>
                        <div
                          class="description-detail"
                          *ngIf="item.description"
                        >
                          <p
                            *ngIf="!readMore[item.id]"
                            class="menu-description normal-txt"
                          >
                            {{ item.description | slice : 0 : 30
                            }}{{ item.description?.length > 30 ? "..." : "" }}
                          </p>
                          <p
                            *ngIf="readMore[item.id]"
                            class="menu-description normal-txt"
                          >
                            {{ item.description }}
                          </p>
                          <button
                            *ngIf="
                              !readMore[item.id] &&
                              item.description?.length > 30
                            "
                            class="readMoreBtn"
                            (click)="readMore[item.id] = !readMore[item.id]"
                          >
                            read more
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="menu-item-right-sec">
                      <img
                        *ngIf="item.image"
                        (click)="largeImage[item.id] = !largeImage[item.id]"
                        src="{{ item.image }}"
                        alt="memu img"
                        class="restaurant-menu-img"
                      />
                      <button
                        *ngIf="!item.qty && !data.details.meta.order_disabled"
                        (click)="addToCart(item)"
                        class="menu-add-btn"
                      >
                        ADD
                        <p class="plus-btn">
                          <span class="add-icon-line-1"></span>
                          <span class="add-icon-line-2"></span>
                        </p>
                      </button>
                      <div
                        class="menu-item-count-sec"
                        *ngIf="item.qty && !data.details.meta.order_disabled"
                      >
                        <button
                          class="sub-btn"
                          (click)="manageItem(item, false)"
                        >
                          <span class="sub-icon-line"></span>
                        </button>
                        <input
                          type="text"
                          class="menu-item-count-input"
                          readonly
                          value="{{ item.qty }}"
                        />
                        <button
                          class="plus-btn"
                          (click)="manageItem(item, true)"
                        >
                          <span class="add-icon-line-1"></span>
                          <span class="add-icon-line-2"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div *ngIf="searchText" class="menu-inner-list">
            <div *ngFor="let item of menuItems" class="menu-list-item">
              <div
                class="menu-item-contant {{
                  largeImage[item.id] ? 'menu-img-lg' : ''
                }}"
                [ngClass]="item.image ? 'menu-img-sm' : ''"
              >
                <div class="menu-contant-top">
                  <div class="menu-contant-left">
                    <div class="food_icons" *ngIf="item.food_types">
                      <ng-container *ngFor="let food_type of shortFoodType(item.food_types)">
                        <ng-container *ngIf="food_type === 'non-veg' || food_type === '0'; else vegFoodType">
                          <img src="./../../assets/images/food-icons/non-veg.png" />
                        </ng-container>
                        <ng-template #vegFoodType>
                          <ng-container *ngIf="food_type === 'veg' || food_type === '1' || food_type === '2'; else otherFoodType">
                            <img src="./../../assets/images/food-icons/veg.png" />
                          </ng-container>
                          <ng-template #otherFoodType>
                            <img src="./../../assets/images/food-icons/{{ food_type }}.png" />
                          </ng-template>
                        </ng-template>
                      </ng-container>
                    </div>
                    <div
                      class="restaurantName {{
                        item.is_veg == 2 ? 'ml24' : ''
                      }}"
                    >
                      <div
                        class="menu-green-dot"
                        *ngIf="item.is_veg == 1" ></div>
                      <div class="menu-red-dot" *ngIf="item.is_veg == 0"></div>
                      <h4 class="menu-item-heading">{{ item.name }}</h4>
                    </div>

                    <p
                      *ngIf="item.price"
                      class="menu-amount normal-txt ml24 mb5"
                    >
                       <span class="font400">{{ item.is_show_currency ? '₹' : 'Price ' }}</span>{{ item.price }} 
                    </p>
                    <div class="description-detail" *ngIf="item.description">
                      <p
                        *ngIf="!readMore[item.id]"
                        class="menu-description normal-txt"
                      >
                        {{ item.description | slice : 0 : 30
                        }}{{ item.description?.length > 30 ? "..." : "" }}
                      </p>
                      <p
                        *ngIf="readMore[item.id]"
                        class="menu-description normal-txt"
                      >
                        {{ item.description }}
                      </p>
                      <button
                        *ngIf="
                          !readMore[item.id] && item.description?.length > 30
                        "
                        class="readMoreBtn"
                        (click)="readMore[item.id] = !readMore[item.id]"
                      >
                        read more
                      </button>
                    </div>
                  </div>
                </div>

                <div class="menu-item-right-sec">
                  <img
                    *ngIf="item.image"
                    (click)="largeImage[item.id] = !largeImage[item.id]"
                    src="{{ item.image }}"
                    alt="memu img"
                    class="restaurant-menu-img"
                  />
                  <button
                    *ngIf="!item.qty && !data.details.meta.order_disabled"
                    (click)="addToCart(item)"
                    class="menu-add-btn"
                  >
                    ADD
                    <p class="plus-btn">
                      <span class="add-icon-line-1"></span>
                      <span class="add-icon-line-2"></span>
                    </p>
                  </button>
                  <div class="menu-item-count-sec" *ngIf="item.qty">
                    <button class="sub-btn" (click)="manageItem(item, false)">
                      <span class="sub-icon-line"></span>
                    </button>
                    <input
                      type="text"
                      class="menu-item-count-input"
                      readonly
                      value="{{ item.qty }}"
                    />
                    <button class="plus-btn" (click)="manageItem(item, true)">
                      <span class="add-icon-line-1"></span>
                      <span class="add-icon-line-2"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p *ngIf="menuItems == 0" class="no-result">No results found</p>
        </div>
      </div>
      <div class="menu-footere">
        <div class="food_icons_footer">
          <img src="./../../assets/images/food-icons/veg.png" />
          <img src="./../../assets/images/food-icons/chef-spcl.png" />
          <img src="./../../assets/images/food-icons/celery.png" />
          <img src="./../../assets/images/food-icons/crustaceans.png" />
          <img src="./../../assets/images/food-icons/dairy.png" />
          <img src="./../../assets/images/food-icons/eggs.png" />
          <img src="./../../assets/images/food-icons/fish.png" />
          <img src="./../../assets/images/food-icons/glutan.png" />
          <img src="./../../assets/images/food-icons/milk.png" />
          <img src="./../../assets/images/food-icons/mustard.png" />
          <img src="./../../assets/images/food-icons/nuts.png" />
          <img src="./../../assets/images/food-icons/peanuts.png" />
          <img src="./../../assets/images/food-icons/sesame.png" />
          <img src="./../../assets/images/food-icons/soya.png" />
          <img src="./../../assets/images/food-icons/sulphites.png" />
          <img src="./../../assets/images/food-icons/non-veg.png" />
        </div>
        <div class="phone-no-sec">
          <span>Contact Details : </span>
          <ng-container *ngFor="let item of data.details.phone.split(',')">
            <a class="vikas-phone" href="tel:{{ item }}">{{ item }}</a>
          </ng-container>
        </div>
        <div class="footer-logo-sec">
          <p class="copyright-txt">Copyright @</p>
          <a href="#">
            <img
              alt="foter-logo"
              class="footer-logo"
              src="../../assets/images/footer-logo.png"
            />
          </a>
        </div>
      </div>

      <div
        *ngIf="cart && !data.details.meta.order_disabled"
        class="subtotal-strip active"
      >
        <a [routerLink]="['/order']" class="menu-btn-primary">
          <div class="subtotal-txt heading-16">
            <p class="orderItemCountTxt">
              {{ totalItems }} {{ totalItems > 1 ? "ITEMS" : "ITEM" }}
            </p>
            <p class="orderItemCountAmount">
              ₹ {{ totalPrice }}
              <!-- <span class="orderItemCounttax">plus taxes</span>-->
            </p>
          </div>
          <p class="heading-16 viewCartTxt">
            View Cart
            <img
              src="../../assets/images/arrowStrip.png"
              alt="arrow icon"
              class="stripArrowImg"
            />
          </p>
        </a>
      </div>
    </div>
  </div>
</ng-container>
